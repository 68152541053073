import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  computed,
  inject,
  isDevMode,
} from '@angular/core';
import { SequenceHeaderComponent } from '../header';
import { SequenceStore } from '../../sequence-store';
import { filterNull } from '@cca-common/cdk';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NgClass } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EMPTY, Observable, catchError, finalize, take } from 'rxjs';
import { IconComponent, SpinnerComponent } from '@cca/ui';
import { SequenceComponentResolverFn } from '../../sequence-component-resolver';
import { SequenceDevToolsStore } from '../../dev-tool.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { id } from '@cca-infra/common';
import { SequenceResetDialogComponent } from '../reset-dialog';
import { PermissionService } from '@cca-common/authentication';
import { SequenceErrorComponent } from '../error/error.component';
import {
  SequenceHostDirective,
  SequenceSideSummaryDirective,
} from '../../directives';
import { sequenceNameToken } from '../../sequence-name';

@Component({
  selector: 'cca-sequence',
  imports: [
    NgClass,
    ReactiveFormsModule,
    SequenceHeaderComponent,
    SequenceHostDirective,
    SequenceSideSummaryDirective,
    SpinnerComponent,
    MatDialogModule,
    MatButtonModule,
    IconComponent,
    SequenceErrorComponent,
    TranslocoModule,
  ],
  templateUrl: './sequence.component.html',
  styleUrls: ['./sequence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: SequenceStore,
      useFactory: () => {
        const parentStore = inject(SequenceStore, {
          optional: true,
          skipSelf: true,
        });
        if (parentStore) {
          return parentStore;
        }
        return new SequenceStore();
      },
    },
    SequenceDevToolsStore,
    provideTranslocoScope('sequence'),
  ].filter(filterNull),
})
export class SequenceComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);
  private dialog = inject(MatDialog);
  protected store = inject(SequenceStore);
  private sequenceNameSignal = inject(sequenceNameToken);
  permissions = inject(PermissionService);
  readonly showDevTools = computed(() => {
    return this.permissions.InternalAdmin() || isDevMode();
  });

  // this formGroup is just so we have a parent FormGroup, this is used in the formGroupDirective
  // such that when pressing continue in the header component we trigger a formSubmission
  // alternative it would require registering and unregistering of the form for a step
  protected form = new FormGroup({});

  @Input() errorTitle: string | undefined | null;
  @Input() errorParagraph: string | undefined | null;
  @Input() showButtons: boolean | undefined | null;

  @Input({ required: true })
  componentResolver!: SequenceComponentResolverFn;
  @Input({ required: true })
  set sequenceName(sequenceName: string) {
    // TODO: remove next line when sequenceName gets being cleaned up
    this.store.sequenceName = sequenceName;
    this.sequenceNameSignal.set(sequenceName);
  }

  cdr = inject(ChangeDetectorRef);

  @Input({ required: true })
  completeHandler!: (stepId: string) => Observable<unknown>;
  @Input({ required: false }) sideSummaryComponent!: Type<unknown>;
  @Input({ required: false }) hideSideSummary = false;
  @Input({ required: true }) resetSequenceLabel!: string;
  @Input({ required: true }) completionInProgressLabel!: string;
  @Input({ required: true }) completeFailedText!: string;
  @Input({ required: true }) repeatStepButtonText!: string;
  @Input() finishButtonLabel: string | null = null;
  @Input() hideHeaderDescription = false;

  @Output() repeatStep = new EventEmitter<void>();

  @Input() set entityId(entityId: id | undefined) {
    this.store.entityId = entityId;
  }

  @ViewChild(SequenceHostDirective) sequenceHostDirective:
    | SequenceHostDirective
    | undefined;

  protected completeFailedError: string | boolean = false;
  protected completeInProgress = false;

  protected title = computed(() => {
    const step = this.store.currentActiveStep();
    if (!step) {
      return null;
    }

    return step?.metaData?.find((x) => x.identifier === 'Title')?.value ?? null;
  });

  protected description = computed(() => {
    const step = this.store.currentActiveStep();
    if (!step) {
      return null;
    }

    return (
      step?.metaData?.find((x) => x.identifier === 'Description')?.value ?? null
    );
  });

  protected showContinueButton = computed(() => {
    const step = this.store.currentActiveStep();
    if (!step) {
      return true;
    }

    return !step.hideContinueButton;
  });

  protected isSummaryStep = computed(() => {
    const step = this.store.currentActiveStep();
    if (!step) {
      return false;
    }

    return step.isSummaryStep;
  });

  protected showSideSummary = computed(() => {
    return !this.isSummaryStep() && !this.hideSideSummary;
  });

  protected summaryToggled = false;

  toggleSummary() {
    this.summaryToggled = !this.summaryToggled;
  }

  ngOnInit() {
    this.store.getCurrentSequenceState();
  }

  resetSequence() {
    const dialogRef = this.dialog.open(SequenceResetDialogComponent);
    return dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.resetSequence();
      }
    });
  }

  reset() {
    this.store.resetSequence();
  }

  back() {
    this.store.navigateBackTo();
  }

  navigate(stepId: id) {
    this.store.navigateBackTo(stepId);
  }

  continueHandler() {
    const step = this.store.currentActiveStep();
    if (!step?.isSummaryStep) {
      setTimeout(() => {
        this.store.continueToNextStep();
      });
      return;
    }

    if (this.completeInProgress) {
      return;
    }

    const sequenceStateId = this.store.sequenceStateId();

    if (sequenceStateId) {
      // set completeInProgress to true, and when the completeHandler finishes put it back to false
      this.completeInProgress = true;
      this.completeHandler(sequenceStateId)
        .pipe(
          // should only emit once, therefore we wan't to complete it after taking 1 value
          take(1),

          // should complete when the view is destroyed
          takeUntilDestroyed(this.destroyRef),

          // finally should set completeInProgress to false
          finalize(() => (this.completeInProgress = false)),
          catchError((err) => {
            this.completeFailedError = err?.error ?? true;
            this.cdr.detectChanges();
            return EMPTY;
          }),
        )
        .subscribe();
    } else if (isDevMode()) {
      console.warn(
        `tried to run the completeHandler without a valid sequenceStateId: ${sequenceStateId}`,
      );
    }
  }

  ngOnDestroy(): void {
    this.sequenceNameSignal.set(null);
  }
}
