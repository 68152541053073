import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslocoModule, provideTranslocoScope } from '@jsverse/transloco';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '@cca/ui';

@Component({
  selector: 'cca-sequence-confirm-dialog',
  imports: [TranslocoModule, MatDialogModule, MatButtonModule, IconComponent],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('sequence')],
})
export class SequenceConfirmDialogComponent {}
